export default {
  "aor": {
    "action": {
      "add_filter": "Filter",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "close": "Schließen",
      "create": "Erstellen",
      "delete": "Löschen",
      "edit": "Bearbeiten",
      "list": "Liste",
      "refresh": "Aktualisieren",
      "remove_filter": "Filter entfernen",
      "save": "Speichern",
      "show": "Detail"
    },
    "auth": {
      "avatar": "Bildavatar URL (optional)",
      "bio": "Kurz-Bio (optional)",
      "education": "Ihre Ausbildung (optional)",
      "email": "E-Mail",
      "first_name": "Vorname (optional)",
      "last_name": "Nachname (optional)",
      "logged_in": "Angemeldet",
      "logged_out": "Abgemeldet",
      "logging_in": "Angemeldet",
      "login": "Login",
      "logout": "Logout",
      "name": "Name",
      "password": "Passwort",
      "sign_in": "Anmelden",
      "sign_in_error": "Authentifizierung fehlgeschlagen, bitte wiederholen",
      "sign_up": "Anmelden",
      "sign_up_error": "Anmeldung fehlgeschlagen, bitte wiederholen",
      "signing_up": "Angemeldet",
      "username": "Benutzername",
      "website": "Ihre Website (optional)"
    },
    "boolean": {"false": "Negativ", "true": "Positiv"},
    "edit_profile": "Profil Bearbeiten",
    "input": {
      "autocomplete": {"none": ""},
    },
    "message": {
      "about": "Info",
      "are_you_sure": "Sind Sie sicher?",
      "no": "Nein",
      "not_found": "Nichts gefunden",
      "yes": "Ja"
    },
    "navigation": {
      "next": "Weiter",
      "no_more_results": "Keine Ergebnisse mehr gefunden",
      "no_results": "Noch nie geändert: keine Revision zu zeigen,",
      "page_out_from_begin": "Kann nicht vor Seite 1 zeigen",
      "page_out_from_end": "Kann nicht über letzte Seite zeigen",
      "page_out_of_boundaries": "Seite %{page} nicht gefunden",
      "page_range_info": "%{offsetBegin}-%{offsetEnd} von %{total}",
      "prev": "Zurück"
    },
    "notification": {
      "created": "Erfolgreich erstellt",
      "deleted": "Wurde erfolgreich gelöscht",
      "http_error": "Server Fehler",
      "item_doesnt_exist": "Element nicht vorhanden",
      "updated": "Erfolgreich aktualisiert"
    },
    "page": {
      "create": "Erstellen von %{name}",
      "dashboard": "Dashboard",
      "delete": "Löschen von %{name} #%{id}",
      "edit": "%{name} #%{id}",
      "list": "%{name} - Liste",
      "not_found": "Nicht Gefunden",
      "show": "%{name} #%{id}"
    },
    "profile_image": "Avatar",
    "validation": {
      "email": "Keine gültige E-Mail",
      "maxLength": "%{max} Zeichen oder weniger",
      "maxValue": "%{max} oder weniger",
      "minLength": "mindestens %{min} Zeichen",
      "minValue": "mindestens %{min}",
      "number": "Muss eine Zahl sein",
      "required": "Erforderlich"
    }
  },
  "askQuestion": "Frage stellen",
  "auth": {
    "logged_in": "Angemeldet",
    "logged_out": "Abgemeldet",
    "login": "Login",
    "logout": "Logout",
    "sign_in": "Anmelden",
    "sign_up": "Registrieren"
  },
  "chartSunburst": {
    "breakdownTitle": "Zusammensetzung der %{dontTranslate}",
    "hoverClickItems": "hover/ klick Elemente",
    "switchChart": "Chart Typ wechseln"
  },
  "influence": {
    "year": "Jahr",
    "provinces": "Provinzen",
    "populationTotal": "Bevölkerung Insgesamt",
    "populationShare": "Bevölkerung Anteil"
  },
  "community": {
    "biographyShort": "Bio",
    "board": "Board",
    "highscore": "Mitglieder Highscore",
    "memberSince": "Mitglied seit",
    "myProfile": "Mein Profil",
    "supportingMember": "Fördernde Mitglieder",
    "totalPoints": "Punkte",
    "loginCount": "Login Anzahl",
    "voteCount": "Abgestimmt",
    "createCount": "Erstellt",
    "editCount": "Bearbeitet",
    "deleteCount": "Gelöscht",
    "mistakesCount": "Fehler",
    "revertCount": "Reverted",
    "linkedCount": "Verknüpft"
  },
  "didYouKnow": {
    "escape": "Artikel können auch mit der ESC Taste geschlossen werden",
    "coloring": "Sie können den Kartentype von Politisch zu Religiös, Kulturell und Anwohnerzahl ändern",
    "discover": "Durch das Kompass-Symbol in der Menüleiste können sie Bilder und Filme zum ausgewählten Jahr anzusehen",
    "distribution": "Sie können die Verteilung der Menschen in einem Reich analysieren, indem Sie auf das Kreis-Symbol auf einem Artikel klicken",
    "link": "Sie können verwandte Artikel miteinander verlinken",
    "markerlimit": "Für eine bessere Website-Leistung können sie den Markerlimit herunterregeln",
    "province": "Sie können eine vollständige Geschichte einer Provinz einsehen, indem Sie auf Provinz im Artikel-header klicken",
    "question": "Zu jedem Artikel können Fragen gestellt und beantwortet werden",
    "timeline": "Sie können mit der Maus die Zeitleiste scrollen und skalieren"
  },
  "introHints": {
    "step1": "Scrollen und klicken Sie irgendwo auf die Zeitleiste (1/3)",
    "step2": "Öffnen Sie das Layer-Menü und wählen Sie einige Marker und Epen aus (2/3)",
    "step3": "Öffnen Sie die \"Media\" - Sektion um Bilder und Filme zum ausgewählten Jahr anzusehen (3/3)",
    "stepFinal": "Das sind die Grundlagen. Es gibt noch viele weitere Features zu entdecken. Genießen Sie die Reise!",
    "close": "Intro schließen"
  },
  "benchmarkPage": {
    "tier5Header": "Keine",
    "title": "Einstellungen",
    "tier1Header": "Sauber & Schnell",
    "tier1Description": "Nur die Karten Grenzen und Beschriftungen.",
    "tier2Header": "Mittelweg",
    "tier2Description": "Karten Grenzen, Beschriftungen und maximal 2000 Marker pro Jahr.",
    "tier3Header": "Komplett",
    "tier3Description": "Epen in der Zeitleiste und ein Maximum von 5000 Marker pro Jahr.",
    "hint": "Keine Sorge, Sie können alle Einstellungen auch später anpassen!",
    "suggestion1": "Die Option <b>%{dontTranslate}</b> passt am besten auf ",
    "suggestion2": "ihr System",
    "warningMobile": "Leider haben wir noch keine Mobilversion.",
    "warningTablet": "Leider haben wir noch keine Unterstuetzung fuer touch screens.",
    "continue": "Weiter",
    "specsTitle": "Ihre Systemdetails",
  },
  "loadingPage": {
    "errorMessage": "Chronas scheint unter zu viel Last zu sein. Bitte versuchen Sie es in ein paar Minuten erneut.",
    "loadingScreenDescription": {
      "218": "Der römische Kaiser Elagabalus (203-222 AD) mit Bankett",
      "400": "Honorius, Sohn des Theodosius I; als Junior Augustus für das Weströmische Reich ernannt",
      "450": "450-511: Ausbildung der Kinder Chlodwigs",
      "711": "711: Schlacht von Guadalete von den Westgoten zu Beginn der Umayyadischen Eroberung von Hispania",
      "881": "881: Die Schlacht der dänischen Truppen von Wikingern und der christlichen Truppen der Karolinger",
      "1121": "1121: Die Auswirkung der Didgori-Schlacht dargestellt im \"Geist des Reiters\" von Augusto Ferrer-Dalmau",
      "1331": "1331: Die Belagerung von Akasaka in der Nähe von Osaka war einer der früheren Schlachten des Genkō-Kriegs",
      "1582": "1582: Schlacht von Kap Tschuwaschien",
      "1627": "1627-1628: Die Belagerung von La Rochelle (Königreich Frankreich)",
      "1649": "1649: Cromwell bei Dunbar. Oliver Cromwell vereinigte die britischen Inseln und schuf die Commonwealth von England",
      "1832": "1832: Pont Neuf-Brücke in Paris",
      "1862": "1862: Konföderierte gefangen in der Schlacht von Front Royal",
      "1871": "1871: Proklamation des deutschen Reiches in Versailles",
      "-1400": "1400 v. Chr.: Der Sonnenwagen Trundholm ist ein nordische Bronzezeit Artefakt entdeckt in Dänemark.",
      "-2000": "26 v. Chr.: Der Ur-Standard, eine hohle Holzkiste mit Szenen von Krieg und Frieden",
      "-332": "332 v. Chr: Alexander der Große beim unbestrittenen Einmarsch in die Stadt Babylon",
      "-519": "519 v. Chr.: Lucius Quinctius wurde zu einem legendären römischen Verfechter der normalen Bürger",
      "-52": "52 v. Chr.: Die Kapitulation des gallischen Häuptling nach der Schlacht von Alesia",
      "-600": "605-562 v. Chr.: Schreitende Löwen, Neo-Babylonische Reich",
      "-620": "620-570 v. Chr.: Sappho und Alcaeus",
      "-668": "668 v. Chr.: Skulpturenreliefs Ashurbanipal, der letzte große assyrische König bei der Jagd"
    }
  },
  "pos": {
    "about": "Info",
    "account": "Profil",
    "addMedia": "Media hinzufügen",
    "advanced": "Detail",
    "area": "Karte",
    "areas": "Karte",
    "back": "zurück",
    "basemap": "Grundkarte",
    "basemapTypes": {"none": "Transparent", "topographic": "Topographisch", "watercolor": "Aquarell"},
    "block": {
      "about1": "<h4>Was ist Chronas?</h4> <br /> <h5>Übersicht</h5>",
      "about2": "Chronas kann als eine Mischung aus <a target='_blank' href='https://www.openstreetmap.org'>Open Street Map</a>, <a target='_blank' href='https://www.wikipedia.org/'>Wikipedia</a> und <a target='_blank' href='https://earth.google.com/web/'>Google Earth</a> beschrieben werden. Es ordnet 4000 Jahre historischer Herrscher, Kulturen und Religionen Provinzen auf einer Karte zu und verknüpft diese mit Wikipedia-Artikeln sowie verschiedener Arten von Marken (historischen Menschen, Schlachten, Städte, Burgen usw). Verknuepfungen drücken eine Beziehung zueinander aus (z.B. die Verknüpfung einer Stadt mit dem Artikel einer Belagerung dieser Stadt). Darüberhinaus können andere Arten von Medien, wie Videos, Bilder oder Podcasts verknüpft werden. Benutzer können neue Marker bewerten, bearbeiten und hinzufügen oder Media Objekte verlinken. Alle Veränderungen werden durch ein Revisionssystem gesichert. Sie können auch Fragen zu bestimmten Artikeln stellen, und eigene Artikelsammlungen (die sogenannten Epics) erstellen, die auf wichtige Themen wie Königreiche, Kriege oder Erkundungen basieren. Ein Video, das diese Funktionen zeigt finden Sie ",
      "about3": "hier",
      "about4": "<h5>Etwas ausfuehrlicher</h5> <p>Der Name <b>Chronas</b> ist eine Kombination aus <b>Chron</b>os und Atl<b>as</b>, das die chronologische und kartographische Seite der Geschichts-Anwendung ausdrueckt.</p> <p>Die erste Idee Mitte 2014 war einen Zeit-Schieberegler einer Google Maps Anwendung hinzuzufuegen, um so in der Zeit zurück Reisen zu können: Um so Gruendungen von Reichen, das Wachsen und den Niedergäng nachvollziehen zu können. Das Ziel war auch ein besseres Verständnis davon zu bekommen, wie die Geschichte der Welt miteinander verbunden ist. <i>Was ist in Asien passiert als Rom Europa beherrschte? Was geschah in Arabien, als Kublai Khan sich Kaiser von China nannte?</i></p> <p>Die Originalversion von Chronas wurde im Jahr 2015 veröffentlicht und zog über 5000 registrierte Nutzer an. Es basierte auf <a target='_blank' href='https://umap.openstreetmap.fr/en'>Open Street Map</a> und der Raster Kartenbibliothek <a target='_blank' href='https://leafletjs.com/'>Leaflet</a> mit der relational <a target='_blank' href='https://www.postgresql.org/'>PostgreSQL</a> Datenbank. </p> <p> Hier ist ein ~5 Minuten Video, dass die grundlegenden Features der Originalversion zeigt: </p>",
      "about5": "<p> Wenn Sie an der Originalversion interessiert sind, können Sie diese noch unter <a target='_blank' href='http://v0.chronas.org'>http://v0.chronas.org</a> erreichen. Es umfasst auch einen <a target='_blank' href='http://v0.chronas.org/blog'>Entwicklungsblog</a>, der technische Konzept der ersten version ausführlich erklärt und enthält auch zwei 3D-Spin-Off-apps: <a target='_blank' href='http://app.chronas.org/castles/#/wikidata'>Burgen</a> und <a target='_blank' href='http://app.chronas.org/battles/#/wikidata'>Schlachten</a> </p> <br /> <p> Entwicklung der aktuellen version von Chronas begann im Juli 2017 und stellte eine komplette Neuentwicklung der Logik und Architektur dar mit Schwerpunkt auf verbesserte Performance, sowie einfache Bearbeitungen, Revisionen und Verknüpfung verwandter Artikel. An Stelle von Leaflet wird die WebGL Kartenbibliothek <a target='_blank' href='https://www.mapbox.com/mapbox-gl-js/api/'>Mapbox GL</a> verwendet mit einem <a target='_blank' href='https://reactjs.org/'>React JS</a> frontend. Für das backend wurde eine eigens dafuer entwickelte API in <a target='_blank' href='https://expressjs.com'>Express</a> <a target='_blank' href='https://nodejs.org/'>Node-JS</a> geschrieben in Zusammenarbeit mit der NoSQL - <a target='_blank' href='https://nodejs.org/'>Mongo</a> Datenbank. </p> <p>Sie finden ein ausfuehrliches Video zur aktuellen Chronas Version in der <b>Intro</b> Sektion.</p>",
      "contact1": "Entwickler",
      "contact10": "Senden",
      "contact2": "Front-und Backend-Entwicklung",
      "contact3": "DevOps und Entwicklungsunterstützung",
      "contact4": "Übersetzungen von",
      "contact5": "Kontakt",
      "contact6": "Senden Sie uns direkt eine E-Mail (bewegen Sie den Mauszeiger über die E-Mail-Symbole) oder benutzen Sie das Formular:",
      "contact7": "Ihre E-Mail-Adresse*",
      "contact8": "Titel*",
      "contact9": "Nachricht*",
      "howTo1": "Intro (5 Minuten)",
      "howTo2": "So bearbeiten Sie Daten in etwa 6 Minuten (mit englischem Kommentar)",
      "pledge1": "Sie waren nun über <b>15 Minuten</b> auf Chronas!",
      "pledge2": "Chronas lebt von der Unterstuetzung der Nutzer. Wenn Sie dieses Projekt sinnvoll finden, schauen Sie auf ",
      "pledge3": " vorbei und geben Sie einen Betrag ihrer Wahl.",
      "pledge4": "Patreons können das Projekt durch Abstimmungen steuern und über neue Funktionalitäten, Prioritäten und wichtige Design-Entscheidungen abstimmen.",
      "pledge5": "Wenn Sie Patreon nicht verwenden möchten, besteht auch die Möglichkeit einmaliger Spenden via PayPal:",
      "rules1": "<br /> <h4>Regeln Für Data Kuration</h4> <ol> <li><i>Kein Vandalismus</i>: Dies ist nicht der Ort, um zu malen und eine alternative Geschichte zu kreieren. Vandalismus bedroht das gesamte Projekt immens und ist daher verboten. </li> <li><i>Schauen Sie das Tutorialvideo</i> bevor Sie mit der Bearbeitung beginnen. Einige Steuerelemente können zu <i>unerwünschten</i> Ergebnisse fuehren. </li> <li>Ihre hinzugefuegten Daten und Bearbeitungen sollte auf <i>gesicherte Geschichte</i> mit enstprechende Quellen basieren. </li></ol> <br /> <h4>Regeln Für Die Foren</h4> <ol> <li><i>Kein Vandalismus</i>: Kein Rassismus, Fanatismus, oder Anstößiges Verhalten.</li> <li>Fragen <i>Stellen Sie klare und spezifische Fragen</i>.</li> <li>Fragen und Antworten sollten in Englisch gestellt werden. </li> </ol>",
      "statistics1": "Die folgenden Statistiken sind live Daten und repräsentieren einen Schnappschuss der aktuellen Chronas Datenbank",
      "statistics2": "Kartendatenpunkte",
      "statistics3": "Nutzer und Engagements",
      "statistics4": "Marker Verteilung",
      "statistics5": "Kartendatenpunkte",
      "statistics6": "Details",
      "statistics7": "Media Verteilung",
      "statistics8": "Nutzer nach Auth-Typ",
      "statisticsTooltip1": "Kartendatenpunkte: 4000 Jahre abgebildet mit 2479 Provinzen und 4 Dimensionen",
      "statisticsTooltip2": "Zu Marker zählen Menschen und Schlacht-Marker, aber auch Städte etc",
      "statisticsTooltip3": "Zu Medien zählen Medien (Cideos, Bilder,...) aber auch Epen (Artikelsammlungen)",
      "statisticsTooltip4": "Anzahl der Kartenartikel (Politisch, Kulturel und Religiös",
      "statisticsTooltip5": "Angemeldet Nutzer",
      "statisticsTooltip6": "Bearbeitungen der Daten durch die Nutzer",
      "statisticsTooltip7": "Forum Threads (schließt automatisch generiert threads mit ein)",
      "statisticsTooltip8": "Forum Kommentare (schließt automatisch generiert und migriert Antworten mit ein)",
      "support1": "Fuer Onlineanwendungen gibt es einige Optionen die Serverkosten und die Entwicklung von neuen Features und die Behebung von Probleme zu finanzieren. Zum Beispiel verwendet Wikipedia wiederkehrende Kampagnen, die zu Spenden aufrufen (<b>Pledge Drives</b>), viele Internetseiten allgemein nutzen <b>Werbung</b> was erheblich störend fuer den Nutzer sein kann. Eine weitere Option ist die Einführung einer <b>Pro Version</b> oder die Finanzierung eines <b>Kapitalanlegers</b>.",
      "support10": ".",
      "support11": "Wenn Sie daran interessiert sind, Chronas auf Ihrem (oder unserem) Server mit einem vollständigen Daten-Replikat zu nutzen, kontaktieren Sie uns bitte via dem ",
      "support12": "Kontaktformular",
      "support13": ".",
      "support2": "Wir beschlossen ein weiteres Finanzierungsmodell zu versuchen, das den <b>unterstuetzenden Nutzern</b> die Möglichkeit gibt zu entscheiden, wo die Reise hingeht. Wenn Sie an das Projekt glauben, wuerden wir uns sehr über Ihre Unterstütztung auf",
      "support3": " freuen, wo wir regelmäßige <b>Umfragen</b> zu Prioritäten der vorgeschlagenen <b>neuen Funktionen</b> sowie <b>wichtige Designentscheidungen</b> abhalten.",
      "support4": "Wenn Sie Patreon nicht verwenden möchten, besteht auch die Möglichkeit einmaliger Spenden via PayPal:",
      "support5": "Neue Features",
      "support6": "Sie können neue Features im ",
      "support7": "Forum",
      "support8": " vorschlagen oder direkt die Entwickler ",
      "support9": "kontaktieren",
      "welcome11": "<b>Chronas</b> ist eine Geschichts-Karten-Anwendung mit über ",
      "welcome12": "50 Millionen Datenpunkte",
      "welcome13": " die jeder registrierter Nutzer editieren und kuratieren kann, wie <a> - Wikipedia</a>.",
      "welcome21": "Bevor Sie eintachen, empfehlen wir zuerst das kurze ",
      "welcome22": "Tutorial Video",
      "welcome23": " in der Tutorial Sektion anzusehen.",
      "welcome31": "Anmeldung",
      "welcome32": " ist einfach und kostenlos. Als einer der <b>%{dontTranslate}</b> Mitglieder können Sie Daten hinzufügen und bearbeiten, Punkte sammeln, Geschichtsfragen über Artikel stellen und den Zugriff auf unsere ",
      "welcome33": "Foren",
      "welcome34": " erhalten um neue Features vorzuschlagen und Bugs zu markieren.",
      "welcome41": "Besuchen Sie die ",
      "welcome42": "Info",
      "welcome43": " Sektion um mehr über das Chronas Projekt zu erfahren und schicken Sie den Entwicklern Anfragen, Kommentare oder Fragen, durch das ",
      "welcome44": "Kontakt",
      "welcome45": " Formular.",
      "welcome51": "Wir glauben an das Potenzial dieses Projekts das Geschichtswerkzeug neben Wikipedia zu werden. Wenn Sie unsere Begeisterung teilen und das Projekt unterstützen wollen, finden Sie weitere Informationen zur <b>Zukunft von Chronas</b> in der ",
      "welcome52": "Unterstützung",
      "welcome53": " Sektion."
    },
    "clusterMarker": "Cluster Marker",
    "community": "Community Board",
    "configuration": "Konfiguration",
    "discover": "Media",
    "discover_component": {
      "seeMore1": "See more media in the ",
      "seeMore2": " section...",
      "noFound": "No images found",
      "noGeotaggedFound": "No geotagged images found in this location, see Discover section for all available images",
      "edit": "Bearbeiten",
      "hasNoArticle": "Noch kein Wiki-Artikel verlinkt",
      "hasNoSource": "Noch keine Quelle verlinkt",
      "openArticle": "Öffne verwandten Wikipedia-Artikel",
      "openSource": "Öffne Quelle in neuem Tab",
      "tabs": {
        "HIGHLIGHTS": "HIGHLIGHTS",
        "ARTEFACTS": "ARTEFAKTE",
        "EPICS": "EPEN",
        "PEOPLE": "MENSCHEN",
        "CITIES": "STÄDTE",
        "BATTLES": "SCHLACHTEN",
        "OTHER": "ANDERE",
        "VIDEOS": "VIDEOS",
        "PRIMARY SOURCES": "PRIMÄRE QUELLEN"
      }
    },
    "discover_label": "Entdecken Sie das Jahr ",
    "downvote": "Unpassend, kaputt oder fehlerhaft",
    "edit": "Bearbeiten",
    "epicIdNameArray": {
      "ee": "Explorationen",
      "ei": "Erfindungen",
      "eo": "Andere Epen",
      "es": "Primäre Quelle",
      "ew": "Kriege"
    },
    "epics": "Epen",
    "migration": "Migration",
    "feedbackSuccess": "Vielen Dank für das Kuratieren der Daten!",
    "filterMedia": "Filter Media",
    "filter": "Filter",
    "fontType": "Schriftart",
    "founded": "est.",
    "fullscreenArticle": "Fullscreen Artikel",
    "general": "Allgemein",
    "goFullScreen": "Fullscreen",
    "goTutorial": "Tutorial öffnen",
    "help": "Intro und Informationen",
    "images": "Bilder",
    "info": {
      "followUs": "Folgen Sie uns auf",
      "lastDataEdit": "Letzte Änderung",
      "tabs": {
        "about": "Über Chronas",
        "contact": "Kontakt",
        "howTo": "Intro",
        "rules": "Regeln",
        "statistics": "Statistik",
        "support": "Unterstützung",
        "welcome": "Willkommen"
      },
      "welcomeTo": "Willkommen auf"
    },
    "information": "Information",
    "label": "Beschriftung",
    "language": "Sprache",
    "layers": "Ebenen",
    "linkedTypes": {
      "artefacts": "[Bild] Artefakt",
      "audios": "[Podcast & Audio]",
      "battles": "[Bild] Schlacht",
      "cities": "[Bild] Stadt & Gebäude",
      "eb": "[Epic] Biographie",
      "ee": "[Epic] Erkundungen",
      "ei": "[Epic] Entdeckungen",
      "eo": "[Epic] Andere",
      "ew": "[Epic] Kriegen",
      "h": "[HTML oder Text]",
      "misc": "[Image] Weitere",
      "o": "Andere",
      "people": "[Bild] Person",
      "ps": "[Link] Primäre Quelle",
      "v": "[Video]",
      "w|a": "[Wiki-Artikel] Menschen -> Künstler",
      "w|ar": "[Wiki-Artikel] Artefakte",
      "w|at": "[Wiki-Artikel] Menschen -> Sportler",
      "w|b": "[Wiki-Artikel] Schlachten -> Schlachten",
      "w|c": "[Wiki-Artikel] Städte -> Städte",
      "w|ca": "[Wiki-Artikel] Städte -> Burgen",
      "w|e": "[Wiki-Artikel] Menschen -> Entdecker",
      "w|l": "[Wiki-Artikel] Andere -> Landmarken",
      "w|m": "[Wiki-Artikel] Menschen -> Militär",
      "w|o": "[Wiki-Artikel] Andere -> Unbekannt",
      "w|op": "[Wiki-Artikel] Menschen -> Unbekannt",
      "w|p": "[Wiki-Artikel] Menschen -> Politiker",
      "w|r": "[Wiki-Artikel] Menschen -> Religiöse",
      "w|s": "[Wiki-Artikel] Menschen -> Wissenschaftler",
      "w|si": "[Wiki-Artikel] Schlachten -> Belagerungen"
    },
    "loading": "läd...",
    "localeWikiNotFound": "Kein deutscher Artikel gefunden",
    "map": "",
    "markerIdNameArray": {
      "Artifact": "Artefakt",
      "Artist": "Künstler",
      "Athlete": "Athleten",
      "Battle": "Schlacht",
      "Capital": "Hauptstadt",
      "Castle": "Burg",
      "City": "Stadt",
      "Explorer": "Explorer",
      "Landmark": "Wahrzeichen",
      "Military": "Militär",
      "Politician": "Politiker",
      "Religious": "Religiöse",
      "Scientist": "Wissenschaftler",
      "Siege": "Belagerung",
      "Unclassified": "Unklassifiziert",
      "Unknown": "Unbekannt"
    },
    "markerLimit": "Marker-Limit",
    "markerMetadataTypes": {
      "a": "Künstler",
      "ae|culture": "Kultur",
      "ae|religion": "Religion",
      "ae|religionGeneral": "Religion Allgemein",
      "ae|ruler": "Lineal",
      "ar": "Artefakt",
      "at": "Athlet",
      "b": "Schlacht",
      "c": "Stadt",
      "ca": "Burg",
      "cp": "Hauptstadt",
      "e": "Explorer",
      "ee": "Exploration",
      "ei": "Entdeckung",
      "eo": "Andere Epische",
      "es": "Primäre Quelle",
      "ew": "Krieg",
      "l": "Wahrzeichen",
      "m": "Militär",
      "o": "Unbekannt",
      "op": "Unclassified",
      "p": "Politiker",
      "ps": "Primäre Quelle",
      "r": "Religiöse",
      "s": "Wissenschaftler",
      "si": "Belagerung"
    },
    "markers": "Marker",
    "markersMedia": "Marker & Medien",
    "markerType": "Marker-Typ",
    "markerTypes": {"abstract": "Abstrakt", "themed": "Thema"},
    "mediaTitle": "Medien: %{dontTranslate}",
    "markerCheckAll": "Alle Marker",
    "markerUncheckAll": "Keine Marker",
    "metadata": "Metadaten",
    "migrationTooltip": "Verbindet Geburtsort mit Todesort und aggregiert nach Standort.",
    "minimize": "Minimieren",
    "mod": "Änderung",
    "next": "weiter",
    "noLinkedContents": "Es gibt noch keine verknüpften Medien",
    "opacityByPopulation": "Farbintensität nach Bevölkerung",
    "openArticle": "Artikel Öffnen",
    "openEpic": "Öffnet diese Artikelsammlung",
    "openImage": "Bild Öffnen",
    "openSource": "Open Quelle",
    "partOf": "Teil von",
    "pledgeOpen": "Öffnen Patreon in neuem Tab",
    "pledgeRemind": "Erinner mich in 15 Minuten",
    "pledgeTitle": "Hey Reisender!",
    "pointsAdded": "Punkte wuden ihrem Profil hinzugefügt",
    "privacyPolicy": "Datenschutzerklärung",
    "random": "Zufälliger Artikel",
    "readDocument": "Dokument lesen",
    "related_item": "VERWANDTE ARTIKEL...",
    "resources": "Ressourcen",
    "revisionHistory": "Revisionliste",
    "revisions": "Revisionen",
    "allRevisions": "Alle Revisionen",
    "search": "Suche",
    "searchMarkers": "Suche Marker",
    "setYearByArticle": "Setze Jahr nach Artikel",
    "share": "Share als link oder Bild",
    "showProvinces": "Provinzen anzeigen",
    "signupToGatherPoints": "Melden Sie sich an um Punkte zu bekommen",
    "skipTutorial": "Ich weiß was ich tue (Tutorial überspringen)",
    "startTyping": "Tippen Sie hier",
    "statistics": {
      "area": "Karte",
      "edits": "Bearbeitungen",
      "entities": "Artikel",
      "markers": "Marker",
      "media": "Medien",
      "replies": "Antworten",
      "threads": "Threads",
      "users": "Nutzer"
    },
    "termsofService": "Nutzungsbedingungen",
    "theme": {
      "dark": "Dunkel",
      "font": "Schriftart",
      "light": "Licht",
      "luther": "Concordia",
      "marker": "Marker",
      "name": "Thema"
    },
    "timeline": {
      "openAutoplayMenu": "Öffnen Sie das Slideshow-Menü",
      "stopAutoplay": "Slideshow stoppen",
      "autoplay": "Autoplay",
      "autoplaySubtitle": "Wechselt Jahr nach gesetzten Interval",
      "stepSize": "Interval in Jahren",
      "expand": "Timeline Vergrößern",
      "delay": "Delay",
      "repeat": "Wiederholen",
      "reset": "Reset-Timeline-Ansicht",
      "startSlideshow": "Diashow Starten",
      "searchEpics": "Epen suchen"
    },
    "toggle": "Wechseln",
    "upvote": "Interessant und zutreffend",
    "users": "Nutzer Verwalten",
    "welcome": "Willkommen"
  },
  "gallery": {
    "toggle": "Gallerie minimieren/ maximieren"
  },
  "resources": {
    "areas": {
      "fields": {
        "capital": "Hauptstadt",
        "color": "Farbe",
        "culture": "Kultur",
        "display_name": "Display Name",
        "endYear": "Bis Zum Jahr",
        "genReligion": "Allgemeine Religion",
        "icon_url": "Symbol (Full commons.wikimedia oder Wikipedia URL)",
        "key": "Entity-Key",
        "main_religion_name": "Allgemeine Religion Name",
        "main_ruler_name": "Herscher Name",
        "population": "Bevölkerung",
        "province": "Provinz",
        "province_list": "Klicken Sie auf die Provinzen",
        "religion": "Religion",
        "religionGen": "Allg. Religion",
        "replaceWith": "Ersetzen",
        "ruler": "Herrscher",
        "search_name": "Suche nach Name",
        "startYear": "Von Jahr",
        "wiki_url": "Artikel (Vollständiger Wikipedia-URL)"
      }
    },
    "linked": {
      "fields": {
        "content": "Beschreibung",
        "description": "Titel",
        "geojson": "Geojson (Epen)",
        "onlyEpicContent": "Nur ein verlinkter Artikel von einem anderen Artikel",
        "poster": "Link zu Poster",
        "source": "Link zur Quelle",
        "src": "Link zur Quelle/ ID",
        "subtype": "Untertyp",
        "wiki": "Verwandter Wikipedia-Artikel",
        "year": "Jahr"
      }
    },
    "flags": {
      "fields": {
        "fixed": "Resolved",
        "subEntityId": "SubEntityId",
        "fullUrl": "FullUrl",
        "wrongWiki": "wrongWiki"
      }
    },
    "links": {
      "fields": {
        "content_list": "Artikel erscheinen in der Inhaltsliste des Artikels",
        "media_list": "Bild/ Video/ Audio erscheint in der Medien-Sektion",
        "source": "Zu verlinkender Artikel"
      }
    },
    "markers": {
      "deleted": "Marker Gelöscht",
      "fields": {
        "capitalEnd": "Bis Jahr",
        "capitalOwner": "Hauptstadtherrscher",
        "capitalStart": "Von Jahr",
        "coo": "Koordinaten",
        "date": "Datum",
        "endYear": "Bis zum Jahr",
        "lastUpdated": "Zuletzt Aktualisiert",
        "lat": "Latitude",
        "lng": "Longitude",
        "name": "Name",
        "rating": "Bewertung",
        "startYear": "Von Jahr",
        "subtype": "Subtyp",
        "type": "Typ",
        "url": "URL",
        "year": "Jahr"
      },
      "name": "Marker |||| Marker",
      "place_marker": "Marker Platzieren",
      "tabs": {
        "reviews": "Bewertungen",
        "stats": "Statistiken"
      }
    },
    "page": {"delete": "Löschen"},
    "revisions": {
      "fields": {
        "entityId": "Personen Id",
        "id": "Id",
        "name": "Name",
        "nextBody": "Next Body",
        "prevBody": "Prev Body",
        "resource": "Ressource",
        "reverted": "Rückgängig gemacht",
        "subtype": "Subtyp",
        "timestamp": "Timestamp",
        "type": "Typ",
        "user": "Nutzer"
      }, "name": "Revision |||| Revisionen"
    },
    "users": {
      "fields": {
        "createdAt": "Erstellt am",
        "education": "Bildung",
        "email": "E-Mail",
        "karma": "Karma",
        "name": "Name",
        "privilege": "Privileg",
        "username": "Benutzername"
      }, "name": "User |||| Users", "page": {"delete": "Konto Löschen"}
    }
  },
  "somethingWentWrong": "Etwas ging schief..."
}
