export const grey50 = '#fafafa'
export const grey100 = '#f5f5f5'
export const grey200 = '#eeeeee'
export const grey300 = '#e0e0e0'
export const grey400 = '#bdbdbd'
export const grey500 = '#9e9e9e'
export const grey600 = '#757575'
export const grey700 = '#616161'
export const grey800 = '#424242'
export const grey900 = '#212121'

export const chronasMainColor = '#fc0'
export const chronasDark = '#000'
export const chronasBright = '#fff'
