export default {
  "aor": {
    "action": {
      "add_filter": "Añadir filtro",
      "back": "Volver",
      "cancel": "Cancelar",
      "close": "Cerrar",
      "create": "Crear",
      "delete": "Eliminar",
      "edit": "Editar",
      "list": "Lista",
      "refresh": "Actualizar",
      "remove_filter": "Quitar este filtro",
      "save": "Guardar",
      "show": "Mostrar"
    },
    "auth": {
      "avatar": "URL de tu foto (opcional)",
      "bio": "Biografía resumida (opcional)",
      "education": "Estudios (opcional)",
      "email": "Email",
      "first_name": "Nombre (opcional)",
      "last_name": "Primer apellido (opcional)",
      "logged_in": "Sesión iniciada",
      "logged_out": "Sesión cerrada",
      "logging_in": "Iniciando sesión",
      "login": "Iniciar sesión",
      "logout": "Cerrar sesión",
      "name": "Nombre",
      "password": "Contraseña",
      "sign_in": "Iniciar sesión",
      "sign_in_error": "Error de autenticación. Inténtalo otra vez.",
      "sign_up": "Registrarse",
      "sign_up_error": "Error al registrarse. Inténtalo otra vez.",
      "signing_up": "Registrándose",
      "username": "Usuario",
      "website": "Tu página web (opcional)"
    },
    "boolean": {
      "false": "No",
      "true": "Sí"
    },
    "edit_profile": "Editar perfil",
    "input": {
      "autocomplete": {
        "none": ""
      }
    },
    "message": {
      "about": "Sobre",
      "are_you_sure": "¿Seguro?",
      "no": "No",
      "not_found": "URL incorrecta o link erróneo.",
      "yes": "Sí"
    },
    "navigation": {
      "next": "Siguiente",
      "no_more_results": "No se han encontrado más resultados",
      "no_results": "Esta entrada no ha sido modificada. No hay historia que mostrar.",
      "page_out_from_begin": "No se puede ir a antes de la página 1.",
      "page_out_from_end": "No se puede ir más allá de la última página.",
      "page_out_of_boundaries": "Página %{page} fuera de rango",
      "page_range_info": "%{offsetBegin}-%{offsetEnd} of %{total}",
      "prev": "Prev"
    },
    "notification": {
      "created": "Creado con éxito",
      "deleted": "Eliminado con éxito",
      "http_error": "Error de comunicación con el servidor",
      "item_doesnt_exist": "El elemento no existe",
      "updated": "Actualizado con éxito"
    },
    "page": {
      "create": "Crear %{name}",
      "dashboard": "Tablero",
      "delete": "Eliminar %{name} #%{id}",
      "edit": "%{name} #%{id}",
      "list": "%{name} List",
      "not_found": "No encontrado",
      "show": "%{name} #%{id}"
    },
    "profile_image": "Avatar",
    "validation": {
      "email": "Debe ser una dirección de email correcta",
      "maxLength": "Deben ser %{max} caracteres o menos",
      "maxValue": "Debe ser %{max} o menos",
      "minLength": "Debe ser %{min} caracteres como mínimo",
      "minValue": "Debe ser al menos %{min}",
      "number": "Debe ser un número",
      "required": "Obligatorio"
    }
  },
  "askQuestion": "Pregunta",
  "auth": {
    "logged_in": "Sesión iniciada",
    "logged_out": "Sesión cerrada",
    "login": "Iniciar sesión",
    "logout": "Cerrar sesión",
    "sign_in": "Registrarse",
    "sign_up": "Unirse"
  },
  "chartSunburst": {
    "breakdownTitle": "Breakdown of %{dontTranslate} subjects",
    "hoverClickItems": "hover/ click items",
    "switchChart": "SWITCH CHART"
  },
  "influence": {
    "year": "Año",
    "provinces": "Provincias",
    "populationTotal": "Población total",
    "populationShare": "Cuota de población"
  },
  "community": {
    "biographyShort": "Bio",
    "board": "Tablero",
    "highscore": "Cifra más alta de datos de los miembros",
    "memberSince": "Miembro desde",
    "myProfile": "Mi perfil",
    "supportingMember": "Apoyo de miembros",
    "totalPoints": "Puntos totales",
    "loginCount": "Contador de inicios de sesión",
    "voteCount": "Contador de votos",
    "createCount": "Contador de entradas",
    "editCount": "Contador de ediciones",
    "deleteCount": "Contador de eliminaciones",
    "mistakesCount": "Contador de errores",
    "revertCount": "Reiniciar contador",
    "linkedCount": "Contador enlazado"
  },
  didYouKnow: {
    "escape": "Puedes cerrar artículos abiertos presionando ESC",
    "coloring": "Puedes cambiar los colores del mapa por religión, cultura o población usando el icono capas de la barra de menú.",
    "discover": "Puedes navegar por imágenes y otros recursos usando el icono de la brújula de la barra de menú.",
    "distribution": "Puedes ver una distribución de la poblarción de un área haciendo click en el icono del círculo de un artículo",
    "link": "Puedes  enlazar artículos para que se muestren juntos en una sección de contenido (artículos) o en la sección de recursos",
    "markerlimit": "Puedes mejorar el rendimiento reduciendo la cantidad de marcadores usando el icono de capas de la barra de menú",
    "province": "Puedes ver la historia completa de una provincia haciendo click en el icono provincia en la cabecera del artículo.",
    "question": "Puedes haer preguntas sobre artículos y responder otras en el foro",
    "timeline": "Puedes usar la rueda del ratón para cambiar la escala de la línea temporal."
  },
  introHints: {
    step1: "Haz zoom con la rueda del ratón en la escala temporal y haz click en cualquier lugar de ella (1/3)",
    step2: "Abre el menú de capas y selecciona marcadores y eventos (2/3)",
    step3: "Abre el menú de recursos para navegar entre imágenes, vídeos y más material relacionado con el año seleccionado (3/3)",
    stepFinal: "Esto es lo más básico. Hay muchas otras posibilidades esperando a que las descubras. ¡Disfruta el viaje!",
    close: "Close introduction"
  },
  benchmarkPage: {
    tier5Header: "Nada",
    title: "Ajustes por defecto",
    tier1Header: "Limpio y rápido",
    tier1Description: "Solo fronteras de mapas y etiquetas",
    tier2Header: "Intermedio",
    tier2Description: "Fronteras, etiquetas y un máximo de 2000 marcadores por año",
    tier3Header: "Completo",
    tier3Description: "Eventos en la línea temporal y un máximo de 5000 marcadores por año",
    suggestion1: "Sugerimos <b>%{dontTranslate}</b> basándonos en ",
    suggestion2: "las especificaciones de tu dispositivo",
    warning1: "A pesar de el soporte para móviles está en nuestra hoja de ruta, todavía no están soportados. Vuélvelo a comprobar más adelante.",
    warning2: "A pesar de que el soporte para tablets está en nuestra hoja de ruta, las pantallas táctiles aún no están soportadas. Vuélvelo a comprobar más adelante.",
    continue: "continuar",
    specsTitle: "Especificaciones de tu dispositivo",
    hint: "Puedes personalizar todos los ajustes a través del menú capas más adelante."
  },
  "loadingPage": {
    "errorMessage": "Chronas está soportando mucha carga en este momento. Vuélvelo a intentar en unos minutos.",
    "loadingScreenDescription": {
      "218": "El joven emperador Heliogábalo (203-222 dC) presidiendo un banquete",
      "400": "Honorio, hijo de Teodosio I, señalado como el joven César de Oriente por Teodosio en el 393",
      "450": "450-511: La educación de los hijos de Clodoveo",
      "711": "711: Batalla de Guadalete, fin del Reino Visigodo Visigoths y comienzo de la conquista de Hispania por los Omeyas",
      "881": "881: Batalla de las tropas danesas de guerros vikingos paganos y las tropas de hispania carolingias.",
      "1121": "1121: Secuelas de la batalla de Didgori retratada en \"El espíritu del jinete\" pintado por Augusto Ferrer - Dalmau.",
      "1331": "1331: El sitio de Akasaka, cerca de Osaka fue una de las primeras batallas de la Guerra Genkō",
      "1582": "1582: Batalla del cabo Chuvash",
      "1627": "1627–1628: Sitio de La Rochelle (Reino de Francia)",
      "1649": "1649: Cromwell en la Batalla de Dunbar. Oliver Cromwell forzó la unión de las Islas Británicas y creó la Commonwealth de Inglaterra.",
      "1832": "1832: Puente Nuevo en París",
      "1862": "1862: Prisioneros confederados capturados en la Batalla de Front Royal.",
      "1871": "1871: Proclamación del Imperio Germánico en Versalles.",
      "-1400": "1400 AC: El carro solar de Trundholm es un artefacto nórdico de bronce descubierto en Dinamarca.",
      "-2000": "26th century AC: Estandarte de Ur, una caja hueca de madera con escenas de guerra y paz.",
      "-332": "332 AC: Entrada triunfal de Alejandro Magno en la ciudad de Babilonia.",
      "-519": "519 AC: Lucio Quincio Cincinato se convirtió en un legendario opositor a los derechos de los plebeyos (los ciudadanos comunes)",
      "-52": "52 AC: Rendición del jefe galo tras la batalla de Alesia.",
      "-600": "605-562 AC: León de Ishtar, Imperio Neo-Babilónico",
      "-620": "620-570 AC: Safo y Alceo",
      "-668": "668 BC: Escultura en relieve representando a Ashurbanipal, el último gran rey asirio, cazando leones"
    }
  },
  "pos": {
    "about": "Sobre Chronas",
    "account": "Gestionar cuenta",
    "addMedia": "Añadir recursos",
    "advanced": "Avanzado",
    "area": "Área",
    "areas": "Áreareas",
    "back": "volver",
    "basemap": "Mapa base",
    "basemapTypes": {
      "none": "Ninguno",
      "topographic": "Topográfico",
      "watercolor": "Marca de agua"
    },
    "block": {
      "about1": "<h4>¿Qué es Chronas?</h4> <br /> <h5>En resumen</h5>",
      "about2": "Chronas se puede describir como una mezcla de <a target='_blank' href='https://www.openstreetmap.org'>Open  Street Map</a>, <a target='_blank' href='https://www.wikipedia.org/'>Wikipedia</a> and <a     target='_blank' href='https://earth.google.com/web/'>Google Earth</a>. Es un mapa de 4000 años de gobernantes históricos (entidades políticas), culturas y religiones en un mapa con enlaces a artículos de Wikipedia así como varios tipos de marcadores como población, batallas, ciudades, castillos, etc. que a su vez pueden enlazarse entre ellos (por ejemplo el enlace entre el sitio de una ciudad y su marcador específico). Y por si fuera poco, se incluyen enlaces a otros tipos de contenidos como vídeos, imágenes o podcasts para crear una web aún más llena de conocimientos. Los usuarios pueden valorar, editar y crear nuevos marcadores, definir áreas, añadir recursos y establecer y editar nuevos marcadores con un sistema de seguimiento histórico. También pueden formular preguntas sobre artículos específicos y crear colecciones de artículos (epopeyas) en los temas principales, como guerras o exploraciones. Existe un vídeo que muestra todas estas funciones.",
      "about3": "aquí",
      "about4": "<h5>Desde el comienzo</h5> <p>El nombre <b>Chronas</b> es una combinación de <b>Chron</b>os y Atl<b>as</b> que ya indican una aproximación a las intenciones cronológicas y cartográficas de esta aplicación histórica.</p> <p> La idea inicial a mediados de 2014 era añadir una barra de desplazamiento temporal a una aplicación del estilo de Google Maps  que hiciera posible viajar atrás en el tiempo, viendo cómo se fundan imperios, cómo crecen y cómo desaparecen.</p> <p>La idea inicial a mediados de 2014 era añadir una barra de desplazamiento temporal a una aplicación del estilo de Google Maps que hiciera posible viajar atrás en el tiempo para observar cómo un imperio nace, crece y desaparece. La meta es conseguir una mejor comprensión de cómo la historia de todo el mundo está conectada. <i>¿Qué ocurría en Asia cuando roma dominaba Europa? ¿Qué ocurría en Arabia cuando Kublai Kan se proclamó a si mismo Emperador de China?</i></p> <p>La versión original de Chronas se publicó en 2015 y atrajo a cerca de 5000 usuarios registrados. Se basó en la unión de   <a target='_blank' href='https://umap.openstreetmap.fr/en'>Open Street Map</a> y usó la librería raster <a target='_blank' href='https://leafletjs.com/'>Leaflet</a> con la base de datos <a target='_blank' href='https://www.postgresql.org/'>PostgreSQL</a> relacionada. </p> <p>   Aquí hay un vídeo de unos 5 minutos explicándolo.",
      "about5": "<p>   Si estás interesado en probar la versión original sigue disponible en <a target='_blank'     href='http://v0.chronas.org'>http://v0.chronas.org</a>.   También incluye el  <a target='_blank' href='http://v0.chronas.org/blog'>blog sobre el desarrollo</a> el cual explica con mayor detalle los detalles técnicos de la versión inicial, así como dos aplicaciones 3D surgidas  <a target='_blank' href='http://app.chronas.org/castles/#/wikidata'>castles</a> y <a     target='_blank' href='http://app.chronas.org/battles/#/wikidata'>battles</a> en lenguaje Wikipedia. </p> <br /> <p>   El desarrollo de la versión actual de Chronas comenzón el julio de 2017 y significó la reescritura completa tanto de lógica y arquitectura enfocándose en una mejora del rendimiento así como de facilitar la edición, revisión y la creación de los enlaces a artículos relacionados. En lugar de Leaflet, se ha usado la librería WebGL de mapas <a target='_blank'     href='https://www.mapbox.com/mapbox-gl-js/api/'>Mapbox   GL</a> con una <a target='_blank' href='https://reactjs.org/'>React JS</a> UI. Para el backend se ha empleado una API dedicada escrita en <a target='_blank' href='https://expressjs.com'>Express</a> <a     target='_blank' href='https://nodejs.org/'>Node JS</a> usa una base de datos NoSQL <a target='_blank'       href='https://nodejs.org/'>Mongo</a>    Para consultas complejas y revisiones. </p> <p> Puedes encontrar una guía detallada de las características de la nueva versión de Chronas en la sección <b>Cómo</b> .</p>",
      "contact1": "Desarrolladores",
      "contact10": "Enviar",
      "contact2": "Desarrollo del frontend y del backend",
      "contact3": "Soporte de DevOps y de desarollo",
      "contact4": "Traducciones por",
      "contact5": "Contacto",
      "contact6": "Envíanos directamente un email. Send us an email directly (pasa por encima del icono del email para mostrar nuestra dirección de email) o usa el formulario a continuación",
      "contact7": "Tu dirección de email*",
      "contact8": "Asunto*",
      "contact9": "Mensaje*",
      "howTo1": "Cómo se usa en unos 5 minutos",
      "howTo2": "Cómo editar en unos 6 minutos (con subtítulos en inglés)",
      "pledge1": "Ya has estado en Chronas algo más de <b>15 minutes</b>!",
      "pledge2": "Chronas depende del apoyo de los usuarios para sobrevivir y crecer. Si encuentras útil este proyecto, apóyalo.",
      "pledge3": " y considera comprometerte con una cantidad de tu elección ",
      "pledge4": "Los Patreons podrán  conducir el proyecto votando las prioridades de sus funciones y participando en las principales decisiones de diseño.",
      "pledge5": "Si prefieres no usar Patreon, puedes hacer una contribución puntual usando PayPal:",
      "rules1": "<br /> <h4>Reglas de buenas maneras</h4> <ol>   <li><i>No vandalismo</i>: Este no es el sitio para dibujar un mapa histórico alternativo. El vandalismo hace un daño inmenso al proyecto (las copias de seguridad cuestan mucho) El baneo será inmediato.    </li>   <li><i>Mira los videos de los tutoriales</i> antes de que empieces a editar. Algunas acciones pueden llevar a resultados <i>no deseados</i>    </li>   <li>Los datos introducidos deben estar basados en la Historia  <i>aceptada</i> En algunos casos se te puede pedir citar fuentes.   </li>   <li><i>Informa </i> de usuarios que rompan estas reglas.</li> </ol> <br /> <h4>Normas para los foros</h4> <ol>   <li><i>No vandalismo</i>: no racismo, fanatismo, o comportamiento ofensivo.</li>   <li>Pregunta <i>de manera clara y específica</i>, con <i>el tiempo y el espacio en la mente</i>.</li>   <li>Provee preferiblemente  <i>fuentes primarias y secundarias</i> si se te piden antes de citar fuentes terciarias, como  <i>Wikipedia</i>.   </li>   <li>Únicamente preguntas sobre los temas tratados: <i>sin chistes</i>, <i>anécdotas</i>, <i>desorden</i>, u     otras <i>digresiones</i>.   </li>   <li><i>Informa</i> de respuestas que rompan estas reglas.</li> </ol>",
      "statistics1": "Las siguientes estadísticas son contadores en directo y representan una instantánea de la base de datos actualizada de Chronas",
      "statistics2": "Punto de datos del mapa",
      "statistics3": "Usuarios y compromiso",
      "statistics4": "Desglose de marcador",
      "statistics5": "Punto de datos del mapa",
      "statistics6": "Detalles",
      "statistics7": "Desglose de recursos",
      "statistics8": "Usuarios por tipo de autenticación",
      "statisticsTooltip1": "Puntos de datos de área: 4000 años mapeados con 2479 provincias con 4 dimensiones",
      "statisticsTooltip2": "Los marcadores incluyen marcadores de población y batallas y también ciudades y otros",
      "statisticsTooltip3": "Los recursos incluyen vídeos, imágenes, audios... y también epopeyas (compilaciónes de artículos)",
      "statisticsTooltip4": "Número de entidades de área para gobernantes, culturas y religiones",
      "statisticsTooltip5": "Usuarios que han iniciado sesión",
      "statisticsTooltip6": "Ediciones de datos creadas por usuarios",
      "statisticsTooltip7": "Hilos de foros (incluidos hilos generados automáticamente y e hilos movidos)",
      "statisticsTooltip8": "Comentarios del foro (incluidas respuestas autogeneradas y movidas)",
      "support1": "Hay algunas formas de financiar el mantenimiento y el desarrollo de nuevas funciones y la resolución de problemas. Por ejemplo, Wikipedia y otros medios de divulgación lanzan recurrentemente campañas para conseguir  (<b>donaciones</b>), muchas aplicaciones web emplean <b>publicidad</b> (que puede reducir la usabilidad y el disfrute de la aplicación). Otra manera es crear una <b>versión pro</b> o tratar de encontrar <b>inversores de capital</b>.",
      "support10": " los desarrolladores",
      "support11": "Si estás interesado en arrancar Chronas en tu servidor (o en el nuestro) con una réplica completa de los datos de la cual tengas el control total, ",
      "support12": "contáctanos",
      "support13": " y estaremos encantados de comentarlo.",
      "support2": "Hemos decidido probar otro modelo de financiación mediante el cual los <b>mecenas,</b> quienes aseguran el futuro de Chronas, también tienen la posibilidad de decidir hacia dónde se dirige este viaje. Si crees en nuestro proyecto, por favor, considera apoyarnos.",
      "support3": " Habrá <b>votaciones</b> regularmente para establecer prioridades sobre las <b>nuevas funciones</b> que se propongan, así como las <b>decisiones importantes de diseño</b>.",
      "support4": "Si no quieres usar Patreon, se pueden hacer donaciones puntuales via PayPal:",
      "support5": "Nuevas características",
      "support6": "Puedes proponer características en el hilo reservado para esto en el ",
      "support7": "foro",
      "support8": " o directamente ",
      "support9": "cóntactando",
      "welcome11": "<b>Chronas</b> es una aplicación de un mapa histórico con más de",
      "welcome12": "50 millones de puntos de datos",
      "welcome13": "en el que cada usuario registrado puede colaborar y contribuir (igual que <a>Wikipedia</a>).",
      "welcome21": "Antes de sumergirte en él, mira el",
      "welcome22": "video tutorial",
      "welcome23": " en la sección cómo.",
      "welcome31": "Únete",
      "welcome32": " a nuestra comunidad de<b>%{dontTranslate}</b> miembros para añadir y editar contenido, ganar puntos, o hacer preguntas sobre los artículos de Historia y accede a nuestro",
      "welcome33": "foro",
      "welcome34": " para sugerir nuevas características y reportar bugs.",
      "welcome41": "Visita la sección ",
      "welcome42": "Sobre nosotros",
      "welcome43": " Para leer más sobre el proyecto Chronas y enviar a los desarrolladores tus dudas, comentarios y preguntas a través del",
      "welcome44": "contacto",
      "welcome45": " formulario.",
      "welcome51": "Creemos en el potencial de este proyecto y en que se puede convertir en una herramienta popular de Historia junto a Wikipedia. Si compoartes nuestro entusiasmo yt quieres ver que el proyecto triunfa, por favor, considera apoyarnos. Para más información sobre <b>apoyo</b> y el <b>futuro de Chronas</b>, visita la ",
      "welcome52": "apoyo",
      "welcome53": " sección."
    },
    "clusterMarker": "Grupo de marcadores",
    "community": "Tablón de la comunidad",
    "configuration": "Configuración",
    "discover": "Descubre",
    "discover_component": {
      "seeMore1": "Consigue más recursos en la ",
      "seeMore2": " sección...",
      "noFound": "No se han encontrado imágenes",
      "noGeotaggedFound": "No hay imágenes geolocalizadas en esta ubicación. Busca en la sección Descubre todas las imágenes disponibles",
      "edit": "Edita esto",
      "hasNoArticle": "No hay ningún artículo de WikiPedia enlazado todavía. Por favor, considera editar esto",
      "hasNoSource": "No hay ninguna fuente enlazada todavía. Por favor, considera editar esto.",
      "openArticle": "Abrir el artículo de WikiPedia relacionado",
      "openSource": "Abrir en una nueva pestaña",
      "tabs": {
        "HIGHLIGHTS": "DESTACADOS",
        "ARTEFACTS": "ARTEFACTOS",
        "EPICS": "EPOPEYAS",
        "PEOPLE": "POBLACIÓN",
        "CITIES": "CIUDADES",
        "BATTLES": "BATALLAS",
        "OTHER": "OTROS",
        "VIDEOS": "VÍDEOS",
        "PRIMARY SOURCES": "FUENTES PRIMARIAS"
      },
    },
    "discover_label": "Descubre el año ",
    "downvote": "Inapropiado, roto o inexacto",
    "edit": "Editar",
    "epicIdNameArray": {
      "ee": "Exploración",
      "ei": "Descubrimiento",
      "eo": "Otra epopeyas",
      "es": "Fuente primaria",
      "ew": "Guerra"
    },
    "epics": "Epopeyas",
    "migration": "Migración",
    "feedbackSuccess": "¡Gracias por revisar el contenido!",
    "flags": "Reportado como contenido inexacto",
    "flag": "Marcar como contenido inexacto",
    "unflag": "Desmarcar como contenido inexacto",
    "filterMedia": "Filtro de contenido",
    "filter": "Filtro",
    "fontType": "Tipo de fuente",
    "founded": "fundado en",
    "fullscreenArticle": "Artículo a pantalla completa",
    "openArticleInNewTab": "Abrir el artículo en una nueva pestaña",
    "general": "General",
    "goFullScreen": "Pantalla completa",
    "goTutorial": "Ir al tutorial",
    "help": "Introducción e información",
    "images": "Imágenes",
    "info": {
      "followUs": "Síguenos",
      "lastDataEdit": "Última edición",
      "tabs": {
        "about": "Sobre nosotros",
        "contact": "Contacta",
        "howTo": "Cómo",
        "rules": "Normas",
        "statistics": "Estadísticas",
        "support": "Apoyo",
        "welcome": "Bienvenida"
      },
      "welcomeTo": "Bienvenida a"
    },
    "information": "Información",
    "label": "Etiqueta",
    "language": "Idioma",
    "layers": "Capas",
    "linkedTypes": {
      "artefacts": "[Imagen] Artefacto",
      "audios": "[Podcast & Audio]",
      "battles": "[Imagen] Battle",
      "cities": "[Imagen] Ciudad y construcción",
      "eb": "[Epopeya] Biography",
      "ee": "[Epopeya] Exploraciones",
      "ei": "[Epopeya] Descubrimientos",
      "eo": "[Epopeya] Otros",
      "ew": "[Epopeya] Guerras",
      "h": "[HTML or Text]",
      "misc": "[Imagen] Otros",
      "o": "Otro",
      "people": "[Imagen] Persona",
      "ps": "[Enlace] fuente primaria",
      "v": "[Vídeo]",
      "w|a": "[Artículo de WikiPedia] Persona -> Artistas",
      "w|ar": "[Artículo de WikiPedia] Artefactos",
      "w|at": "[Artículo de WikiPedia] Persona -> Atletas",
      "w|b": "[Artículo de WikiPedia] Batallas -> Batallas",
      "w|c": "[Artículo de WikiPedia] Cities -> Ciudades",
      "w|ca": "[Artículo de WikiPedia] Cities -> Castillos",
      "w|e": "[Artículo de WikiPedia] Persona -> Exploradores",
      "w|l": "[Artículo de WikiPedia] Otro -> Lugar emblemático",
      "w|m": "[Artículo de WikiPedia] Persona -> Militar",
      "w|o": "[Artículo de WikiPedia] Otro -> Desconocido",
      "w|op": "[Artículo de WikiPedia] Persona -> Sin clasificar",
      "w|p": "[Artículo de WikiPedia] Persona -> Político",
      "w|r": "[Artículo de WikiPedia] Persona -> Religioso",
      "w|s": "[Artículo de WikiPedia] Persona -> Científico",
      "w|si": "[Artículo de WikiPedia] Batallas -> Asedios"
    },
    "loading": "cargando...",
    "localeWikiNotFound": "No se ha podido encontrar un artículo en español",
    "map": "",
    "markerIdNameArray": {
      "Artifact": "Artefacto",
      "Artist": "Artistas",
      "Athlete": "Atletas",
      "Battle": "Batalla",
      "Capital": "Capital",
      "Castle": "Castillos",
      "City": "Ciudad",
      "Explorer": "Explorador",
      "Landmark": "Lugar emblemático",
      "Military": "Militar",
      "Politician": "Político",
      "Religious": "Religioso",
      "Scientist": "Científico",
      "Siege": "Asedio",
      "Unclassified": "Sin clasificar",
      "Unknown": "Desconocido"
    },
    "markerLimit": "Límite del marcador",
    "markerMetadataTypes": {
      "a": "Artista",
      "ae|culture": "Cultura",
      "ae|religion": "Religión",
      "ae|religionGeneral": "Religion General",
      "ae|ruler": "Gobernante",
      "ar": "Artefacto",
      "at": "Atletas",
      "b": "Batalla",
      "c": "Ciudad",
      "ca": "Castillo",
      "cp": "Capital",
      "e": "Explorador",
      "ee": "Exploración",
      "ei": "Descubrimiento",
      "eo": "Otra epopeya",
      "es": "Fuente primaria",
      "ew": "Guerra",
      "l": "Lugar emblemático",
      "m": "Militar",
      "o": "Desconocido",
      "op": "Sin clasificar",
      "p": "Político",
      "ps": "Fuente primaria",
      "r": "Religioso",
      "s": "Científico",
      "si": "Asedio"
    },
    "markers": "Marcadores",
    "markersMedia": "Marcadores & Recursos",
    "markerType": "Tipo de marcador",
    "markerTypes": {
      "abstract": "Abstracto",
      "themed": "Temático"
    },
    "markerCheckAll": "Seleccionar todos los marcadores",
    "markerUncheckAll": "Ocultar todos los marcadores",
    "mediaTitle": "Media: %{dontTranslate}",
    "metadata": "Metadata",
    "migrationTooltip": "Muestra rutas desde los lugares de nacimiento de personas hacia donde murieron agrupadas por ubicación.",
    "minimize": "Minimizar",
    "mod": "Modificación",
    "next": "siguientes",
    "noLinkedContents": "No hay enlaces con recursos todavía. Considera añadir y enlazar uno.",
    "opacityByPopulation": "Opacidad segú población",
    "openArticle": "Abrir artículo",
    "openEpic": "Abrir una epopeya propia",
    "openImage": "Abrir imagen",
    "openSource": "Abrir fuente",
    "partOf": "Parte de",
    "pledgeOpen": "Abrir Patreon en una nueva pestaña",
    "pledgeRemind": "Recordarme en 15 minutos",
    "pledgeTitle": "Saludos, viajero!",
    "pointsAdded": "Puntos añadidos a tu cuenta",
    "privacyPolicy": "Política de privacidad",
    "random": "Artículo al azar",
    "readDocument": "Leer documento",
    "related_item": "CONTENIDO RELACIONADO...",
    "resources": "Recursos",
    "revisionHistory": "Revisión histórica",
    "revisions": "Revisiones",
    "allRevisions": "Todas las revisiones",
    "search": "Buscar",
    "searchMarkers": "Buscar marcadores",
    "setYearByArticle": "Establecer año por el artículo",
    "share": "Compartir enlace o exportar imagen",
    "showProvinces": "Mostrar provincias",
    "signupToGatherPoints": "Inicia sesión para ganar puntos por tus contribuciones",
    "skipTutorial": "Sé lo que estoy haciendo (saltar tutorial)",
    "startTyping": "Empezar a escribir",
    "statistics": {
      "area": "Área",
      "edits": "Ediciones",
      "entities": "Entidades",
      "markers": "Marcadores",
      "media": "Recursos",
      "replies": "Respuestas",
      "threads": "Hilos",
      "users": "Usuarios"
    },
    "termsofService": "Términos del servicio",
    "theme": {
      "dark": "Oscuro",
      "font": "Fuente",
      "light": "Claro",
      "luther": "Concordia",
      "marker": "Marcador",
      "name": "Tema"
    },
    "timeline": {
      "openAutoplayMenu": "Abrir menú de reproducción automática",
      "stopAutoplay": "Parar reproducción automática",
      "autoplay": "Reproducción automática",
      "autoplaySubtitle": "El año cambia según los años del salto",
      "stepSize": "Años en cada salto",
      "expand": "Expandir línea temporal",
      "delay": "Retraso en segundos",
      "repeat": "Repetir",
      "reset": "Resetear vista de la línea temporal",
      "startSlideshow": "Comenzar transición",
      "searchEpics": "Buscar epopeyas"
    },
    "toggle": "Activar",
    "upvote": "Interesante y preciso",
    "users": "Administrar usuarios",
    "welcome": "Bienvenida"
  },
  "resources": {
    "areas": {
      "fields": {
        "capital": "Capital",
        "color": "Color del área",
        "culture": "Cultura",
        "display_name": "Mostrar nombre",
        "endYear": "Hasta el año",
        "genReligion": "Religión mayoritaria",
        "icon_url": "Icono (Full commons.wikimedia o URL Wikipedia )",
        "key": "Entity Key",
        "main_religion_name": "Nombre de la religión principal",
        "main_ruler_name": "Nombre del gobernante principal",
        "population": "Población",
        "province": "Provincia",
        "province_list": "Haz click en provincias para seleccionar o deseleccionar",
        "religion": "Religión",
        "religionGen": "Religion Gen.",
        "replaceWith": "Sustituir con",
        "ruler": "Gobernante",
        "search_name": "Seleccionar por nombre",
        "startYear": "Desde el año",
        "wiki_url": "Artículo ((URL completa de WikiPedia)"
      }
    },
    "flags": {
      "fields": {
        "fixed": " Resuelto",
        "subEntityId": "SubEntityId",
        "fullUrl": "URLCompleta",
        "wrongWiki": "Wiki erróneo"
      }
    },
    "linked": {
      "fields": {
        "content": "Descripción",
        "description": "Título",
        "geojson": "Geojson (para epopeyas)",
        "onlyEpicContent": "Solo se usa como parte de otro artículo (se te llevará al formulario de enlace una vez se guarde)",
        "poster": "Enlace a imágen de cabecera",
        "source": "Enlace a fuente",
        "src": "Enlace a fuente/ID",
        "subtype": "Tipo",
        "wiki": "Artículo de Wikipedia asociado",
        "year": "Año"
      }
    },
    "links": {
      "fields": {
        "content_list": "Contenido que aparecerá en sección lista de contenido a la izquierda",
        "media_list": "Contenido que aparecerá en la sección recursos",
        "source": "Artículo fuente que recibe los enlaces"
      }
    },
    "markers": {
      "deleted": "Marcador borrado",
      "fields": {
        "capitalEnd": "Año final",
        "capitalOwner": "Gobernante",
        "capitalStart": "Año de comienzo",
        "coo": "Coordenadas",
        "date": "Fecha",
        "endYear": "Año final",
        "lastUpdated": "Última actualización",
        "lat": "Latitud",
        "lng": "Longitud",
        "name": "Nombre",
        "rating": "Valoración",
        "startYear": "Año de comienzo",
        "subtype": "Subtipo",
        "type": "Tipo",
        "url": "URL",
        "year": "Año"
      },
      "name": "Marcador |||| Marcadores",
      "place_marker": "Ubicar marcador",
      "tabs": {
        "reviews": "Revisiones",
        "stats": "Estadísticas"
      }
    },
    "page": {
      "delete": "Eliminar"
    },
    "revisions": {
      "fields": {
        "entityId": "Entity Id",
        "id": "Id",
        "name": "Nombre",
        "nextBody": "Cuerpo siguientes",
        "prevBody": "Cuerpo anterior",
        "resource": "Recursos",
        "reverted": "Revertido",
        "subtype": "Subtipo",
        "timestamp": "Marca de tiempo",
        "type": "Tipo",
        "user": "Usuario"
      },
      "name": "Revisión |||| Revisiones"
    },
    "users": {
      "fields": {
        "createdAt": "Creado",
        "education": "Educación",
        "email": "Email",
        "karma": "Karma",
        "name": "Nombre",
        "privilege": "Privilegios",
        "username": "Nombre de usuario"
      },
      "name": "Usuario |||| Usuarios",
      "page": {
        "delete": "Borrar cuenta"
      },
    }
  },
  "somethingWentWrong": "Algo falló"
}
