export const START_FETCHING_DISCUSSIONS = 'start_fetching_discussions';
export const STOP_FETCHING_DISCUSSIONS = 'stop_fetching_discussions';
export const FETCHING_DISCUSSIONS_SUCCESS = 'fetching_discussions_success';
export const FETCHING_DISCUSSIONS_FAILURE = 'fetching_discussions_failure';

export const START_FETCHING_PINNED_DISCUSSIONS = 'start_fetching_pinned_discussions';
export const STOP_FETCHING_PINNED_DISCUSSIONS = 'stop_fetching_pinned_discussions';
export const FETCHING_PINNED_DISCUSSIONS_SUCCESS = 'fetching_pinned_discussions_success';
export const FETCHING_PINNED_DISCUSSIONS_FAILURE = 'fetching_pinned_discussions_failure';

export const UPDATE_SORTING_METHOD = 'update_sorting_method';
export const INVALID_FORUM = 'invalid_forum';
